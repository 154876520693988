import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import Modals from "../components/modal"

import footer from '../scss/footer.module.scss'

import Logo from '../images/lgo-stand.inline.svg'
import SRlogo from '../images/lgo-sr.inline.svg'



export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: {relativeDirectory: {eq: "signatories"}}) {
            edges {
              node {
                publicURL
                name
                id
                extension
              }
            }
          }
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `}
      render={data => (

        <>
        <div className={footer.footer} data-animate="fade" data-animate-delay="300">

          {/* logo */}
          <div className={footer.container}>
            <div className={footer.firstCol}><h3>Black Voices Matter.</h3></div>
            <div className={footer.secondCol}>
              <Link id="footerlogolink" to="/" className={footer.logoLink} title={data.site.siteMetadata.title}>
                <Logo id="footerlogo" className={footer.logo} />
              </Link>
              <p className={footer.launched}>An initiative launched by <a href="https://studioresonate.com" aria-label="Studio Resonate" rel="noreferrer" className="srlogofootercontainer" target="_blank"><SRlogo className={`srlogofooter ${footer.srlogo}`} /></a></p>
            </div>
          </div>

          {/* signatories */}
          <ul className={footer.signatoriesList}>
            <li><p>Signatories</p></li>
            <li><a id="logo_pandora_link" href="https://www.pandora.com" target="_blank" rel="noreferrer" title="Pandora"><img id="logo_pandora" className={`signatories-pandora ${footer.signatoriesLogos}`} src="/img/signatories-pandora.svg" alt="Pandora" /></a></li>
            <li><a id="logo_siriusxm_link" href="https://www.siriusxm.com" target="_blank" rel="noreferrer" title="SiriusXM"><img id="logo_siriusxm" className={`signatories-siriusxm ${footer.signatoriesLogos}`} src="/img/signatories-siriusxm.svg" alt="SiriusXM" /></a></li>
            <li><a id="logo_stitcher_link" href="https://www.stitcher.com" target="_blank" rel="noreferrer" title="Stitcher"><img id="logo_stitcher" className={`signatories-stitcher ${footer.signatoriesLogos}`} src="/img/signatories-stitcher.svg" alt="Stitcher" /></a></li>

            {/* {data.allFile.edges.map(edge => {
              return <li key={edge.node.id}><img className={`${edge.node.name} ${footer.signatoriesLogos}`} src={edge.node.publicURL} alt={edge.node.name} /></li>
            })} */}
            <li id="footerform">
              <button id="footerformlink" className="cta__footer__standwithus button primary" data-modal-open="standwithus" >Stand with us</button>
            </li>
          </ul>
          <p id="copyright" className={footer.legal}>&#169; {new Date().getFullYear()} Pandora Media, LLC. All Rights Reserved • <Link id="link_footer_terms" to="/terms" title="Terms">Terms</Link> • <Link id="link_footer_privacy" to="/privacy" title="Privacy Policy">Privacy Policy</Link></p>

        </div>

        <Modals formId={1558} />


        </>
      )}
    />
  )
}


