import React, { useState, useEffect } from 'react';
import modal from '../scss/modal.module.scss'


const marketoScriptId = 'mktoForms';

export default function MarketoForm({ formId }) {
  // MARKETO FORM
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    isLoaded &&
      window.MktoForms2.loadForm(
        '//app-ab16.marketo.com',
        '983-JVJ-088',
        formId
      );
  }, [isLoaded, formId]);

  const loadScript = () => {
    var s = document.createElement('script');
    s.id = marketoScriptId;
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//app-ab16.marketo.com/js/forms2/js/forms2.min.js';
    s.onreadystatechange = function() {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        setIsLoaded(true);
      }
    };
    s.onload = () => setIsLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(s);
  };
//  END MARKET FORM

  return (
    <>
      <div className={`${modal.modal} micromodal-slide`} id="standwithus" aria-hidden="true">
        <div className="modal__overlay" tabIndex="-1" data-modal-close>
          <div className="modal__container" role="dialog" aria-modal="true" aria-labelledby="standwithus-title" >

            <main className="modal__content_container" id="standwithus-content">


            {/* MARKETO FORM */}
            <div className={modal.content}>
                <div className={`${modal.firstCol} centered`}>
                  <h2>Stand with us</h2>
                  <p>The sonic color line wasn’t drawn overnight. Breaking it requires us to retrain the way we listen, to diversify the sonic spaces we inhabit, and to change the creative choices we’re making that serve to perpetuate systemically racist sonic structures. We can’t do it alone, but together, we can make a difference.</p>
                  <p>Take that first step. Complete and submit the form to learn more about this initiative. Stand with us for sonic diversity.</p>
                </div>

                <div className={`${modal.secondCol} centered`}>
                  <form id={`mktoForm_${formId}`}></form>
                  <div id="confirmform" style={{visibility:'hidden',display: 'none'}}>
                    <h2>Thank you for taking the stand.</h2>
                    <p>We've received your submission and will follow up shortly.</p>
                    <button className="button secondary" aria-label="Close modal" data-modal-close="true">Close</button>
                  </div>
                </div>
              </div>
              {/* END MARKETO FORM */}
            </main>
            <button className="modal__close" aria-label="Close modal" data-modal-close />

          </div>
        </div>
      </div>

      {/* video montage */}

      <div className={`${modal.modalVideo} micromodal-slide`} id="audiomontage" aria-hidden="true">
        <div className="modal__overlay closevideo" tabIndex="-1" data-modal-close>
          <div className="modal__container" role="dialog" aria-modal="true" aria-labelledby="standwithus-title" >
            <main className="modal__content_container" id="standwithus-content">

              <video width="100%" id="sfsdVideo" controls
                poster="/img/poster-montage.png" >
                <source
                  src="/media/SFSD_audio_montage.webm"
                  type="video/webm" />
                <source
                  src="/media/SFSD_audio_montage.mp4"
                  type="video/mp4" />
                <source
                  src="/media/SFSD_audio_montage.ogv"
                  type="video/ogg" />
                <track label="English" kind="captions" srcLang="en" src="/media/SFSD_audio_montage-en.vtt" />
                Sorry. Your browser doesn't support this video.
              </video>

            </main>
            <button className="modal__close closevideo" aria-label="Close modal" data-modal-close />
          </div>
        </div>
      </div>

    </>
  );
}